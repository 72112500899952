import { IsNotEmpty, validate } from "class-validator";
import { bindingBehavior, Binding, AccessScope, BindingBehavior } from "aurelia-binding";
import { View, noView } from "aurelia-framework";



export function bindValidationResult(...properties: string[]): any {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    target.__validationerrorbindings__ = target.__validationerrorbindings__ || [];
    target.__validationerrorbindings__.push(propertyKey);
  };
}


export function bindValidationFired(trigger: ValidationTriggers): any {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    target.__validationerrorbindings_fired__ = target.__validationerrorbindings_fired__ || [];
    target.__validationerrorbindings_fired__.push({ prop : propertyKey, trigger });
  };
}
export enum ValidationTriggers{
  AutoValidation,
  ManualValidation
}