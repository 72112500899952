import { ValidationOptions, registerDecorator, ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from "validation";

@ValidatorConstraint({ name: "equalsProperty", async: false })
export class EqualsPropertyConstraint implements ValidatorConstraintInterface {

    validate(propertyValue: string, args: ValidationArguments) {
        return propertyValue === args.object[args.constraints[0]];
    }

    defaultMessage(args: ValidationArguments) {
      return `"${args.property}" must be equal to "${args.constraints[0]}"`;
    }
}

export function IsNotBlank(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: "isNotBlank",
            target: object.constructor,
            propertyName: propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value: any) {
                    return typeof value === "string" && value.trim().length > 0;
                }
            }
        });
    };
}

export function IsCurrencyString(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: "isCurrencyString",
            target: object.constructor,
            propertyName: propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value: any) {
                    return typeof value === "string" && /^[£$€]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/.test(value);
                }
            }
        });
    };
}

export function IsPostcode(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: "isPostcode",
            target: object.constructor,
            propertyName: propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value: any) {
                    return typeof value === "string" && /^([a-zA-Z][a-hA-Hj-yJ-Y]?[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}|GIR ?0[aA]{2})$/.test(value);
                }
            }
        });
    };
}