import { autoinject, PLATFORM, containerless, useView, customElement, bindable, noView } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";

export class BodyService {
  private claseStack = []

  public currentClass: string = null;

  private innerSetClass(className: string) {
    this.currentClass = className;
    window.document.body.className = className;
  }
  public setClass(className: string): BodyStackItem {
    var itm = new BodyStackItem(className, this);
    this.claseStack.push(itm);
    this.innerSetClass(itm.className);

    return itm;
  }

  public remove(item: BodyStackItem) {
    this.claseStack = this.claseStack.filter(x => x != item);

    if (this.claseStack.length > 0)
      this.innerSetClass(this.claseStack[this.claseStack.length - 1].className);
    else
      this.innerSetClass('');
  }
}

export class BodyStackItem {
  constructor(public className: string, private service: BodyService) {

  }

  public dispose() {
    this.service.remove(this);
  }
}
