import { bindingBehavior, Binding,  BindingBehavior } from "aurelia-binding";
import { View, } from "aurelia-framework";
import { validate, validateBinding, addBindingValidation, removeBindingValidation } from "validation";


@bindingBehavior('validate')
export class ValidateBindingBehavior {
  async bind(binding: Binding, scope: View) {
    await addBindingValidation(binding);
  }

  unbind(binding, scope) {
    removeBindingValidation(binding);
  }
}
