import { autoinject, PLATFORM, containerless, useView, customElement, bindable, noView } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";
import { BodyService, BodyStackItem } from "./bodyService";

@customElement('body-class')
@containerless
@noView
@autoinject
export class BodyClass {

  @bindable className: string = "";
  private current: BodyStackItem = null;
  constructor(private bodyService: BodyService) {

  }

  classNameChanged(n, o) {
    if (o == n) return;
    if (this.current) this.current.dispose();
    this.current = this.bodyService.setClass(n)

  }

  attached() {
    if (this.current) this.current.dispose();
    this.current = this.bodyService.setClass(this.className)
  }

  detached() {
    if (this.current) this.current.dispose();
  }
}
